import { useLocation } from "@remix-run/react";
export const useSearchParams = () => {
  const {
    search
  } = useLocation();
  const params = new URLSearchParams(search);
  return getParams(params);
};
export const urlParams = url => {
  const [, search = ''] = url.split('?');
  const params = new URLSearchParams(search);
  return getParams(params);
};
const getParams = urlSearchParams => Array.from(urlSearchParams.entries()).reduce((previousValue, currentValue) => ({
  ...previousValue,
  [currentValue[0]]: currentValue[1]
}), {});